import { useCallback } from 'react';

import { DateRange } from './DateRangeContext';
import { useAppContext } from '@Contexts/contexts';
import { toSearchParams } from '@Core/helpers/url';
import { URLQueryParams } from '@Core/url/URLQueryParams';
import { ISO_DATE_FORMAT } from '@Dates/dates';
import { SearchSelectionStore } from '@Stores/SearchSelectionStore';
import { stringifyRooms } from '@Stores/SearchSelectionStore/getUrlFromSearchSelection';

type AdditionalParams = Record<string, string>;
interface CommonArgs {
  selection: SearchSelectionStore;
  additionalParams?: AdditionalParams;
}

interface SearchResultsPageArgs extends CommonArgs {
  type: 'DPSRP';
}

interface HotelOnlySearchResultsPageArgs extends CommonArgs {
  type: 'HOSRP';
  dateRange?: DateRange;
  masterId?: string;
}

interface HotelOnlyPandaPageArgs extends CommonArgs {
  type: 'HOPANDA';
  path: string;
}

export type GetSearchResultsPageUrlArgs =
  | SearchResultsPageArgs
  | HotelOnlySearchResultsPageArgs
  | HotelOnlyPandaPageArgs;

export const useSearchResultsPageUrl = () => {
  const { site } = useAppContext();

  return useCallback(
    (args: GetSearchResultsPageUrlArgs) => {
      const { type, selection, additionalParams = {} } = args;

      let path;
      let query;

      // Sunrise SRP
      if (type === 'DPSRP') {
        path = site.pathTemplates.srp;
        query = selection.toUrl('srp');

        if (Object.keys(additionalParams).length) {
          query += `&${new URLQueryParams(toSearchParams(additionalParams)).toString()}`;
        }

        // Horizon HO SRP
      } else if (type === 'HOSRP') {
        const { dateRange, masterId } = args as HotelOnlySearchResultsPageArgs;
        const { destinationIds = [], rooms = [] } = selection;

        path = '/hotel-only/';
        query = new URLQueryParams(
          toSearchParams({
            ...(masterId && { mrIds: masterId }),
            ...(dateRange?.startDate && {
              deptDate: dateRange.startDate.format(ISO_DATE_FORMAT),
            }),
            ...(dateRange?.endDate && {
              retDate: dateRange.endDate.format(ISO_DATE_FORMAT),
            }),
            destinationIds: destinationIds.join(','),
            rooms: stringifyRooms(rooms),
            ...additionalParams,
          }),
        ).toString();

        // Horizon HO Panda
      } else if (type === 'HOPANDA') {
        const { path: pathOverride } = args as HotelOnlyPandaPageArgs;
        const { date, rooms = [], boardBasis = [] } = selection;

        path = pathOverride;
        query = new URLQueryParams(
          toSearchParams({
            ...(date && { date: date.format(ISO_DATE_FORMAT) }),
            ...(!!boardBasis.length && { boardBasis: boardBasis[0] }),
            rooms: stringifyRooms(rooms),
            ...additionalParams,
          }),
        ).toString();
      }

      return `${path}?${query}`;
    },
    [site.pathTemplates],
  );
};
